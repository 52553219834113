import { getCookieValue, setCookieValue } from "./App";
import { get_api_url } from './Util_API_calls';

export const sendOTPDoubleAuth = async (otp_type, isForceSms = false) => {
  const token = getCookieValue("firebaseToken");
  try {
    const params = { delivery_method: isForceSms ? "sms" : "preferred" };
    console.log(params);
    const API_URL = await get_api_url();
    var apiPath;
    switch (otp_type) {
      case 'update_phone_mfa':
        apiPath = '/auth/update-phone-mfa/send-verification';
        break;
      case 'update_email_mfa':
        apiPath = '/auth/update-email-mfa/send-verification';
        break;
      case 'payment_create_mfa':
        apiPath = '/auth/payment-create-mfa/send-verification';
        break;
      case 'card_create_mfa':
        apiPath = '/auth/card-create-mfa/send-verification';
        break;
      default:
        apiPath = '';
        break;
    }
    const response = await fetch(`${API_URL}/api${apiPath}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    }
    );

    if (!response.ok) {
      if (response.status === 500) {
        throw new Error("unexpected_error");
      }
      if (response.status === 429) {
        throw new Error("pleasewait30secs");
      }
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();

    setCookieValue("double_auth_otp_delivery_method", result.delivery_method);
    return result;
  } catch (error) {
    throw error;
  }
};

export const verifyOTPDoubleAuth = async (otp_type, otp_code) => {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    var apiPath;
    switch (otp_type) {
      case 'update_phone_mfa':
        apiPath = '/auth/update-phone-mfa/verify-otp';
        break;
      case 'update_email_mfa':
        apiPath = '/auth/update-email-mfa/verify-otp';
        break;
      case 'payment_create_mfa':
        apiPath = '/auth/payment-create-mfa/verify-otp';
        break;
      case 'card_create_mfa':
        apiPath = '/auth/card-create-mfa/verify-otp';
        break;
      default:
        throw new Error('Impossibile definire il tipo OTP');
    }
    const response = await fetch(`${API_URL}/api${apiPath}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        otp_code: otp_code,
      }),
    }
    );
    if (!response.ok) {
      if (response.status === 500) {
        throw new Error("unexpected_error");
      }
      if (response.status === 402) {
        throw new Error("wrong_otp_code");
      }
      if (response.status === 400) {
        throw new Error("otp_code_expired_please_request_a_new_one");
      }
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setCookieValue(
      "DoubleAuthPaymentCreateExp",
      new Date(Date.now() + 10 * 60000)
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const verifyPushApproval = async (authType) => {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    var apiPath;
    switch (authType) {
      case 'update_phone_mfa':
        apiPath = '/auth/update-phone-mfa/check-approval-status';
        break;
      case 'update_email_mfa':
        apiPath = '/auth/update-email-mfa/check-approval-status';
        break;
      case 'payment_create_mfa':
        apiPath = '/auth/payment-create-mfa/check-approval-status';
        break;
      case 'card_create_mfa':
        apiPath = '/auth/card-create-mfa/check-approval-status';
        break;
      default:
        throw new Error('This type is not supported');
    }
    const response = await fetch(`${API_URL}/api${apiPath}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    );
    if (!response.ok) {
      return false;
    }
    return true;
  } catch (_) {
    return false;
  }
};
