import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, Button, CircularProgress, Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getCookieValue } from './App';
import { NumericFormat } from 'react-number-format';
import { getCurrencySymbol } from './Util_currencies';
import { fetchBankAccountBalance } from './Util_API_calls';
import { number_to_italian_currency, italian_currency_to_number, t } from './Util_format';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { get_api_url } from './Util_API_calls';
import { ErrorContainer, SuccessContainer, ModalTitle, SliderSettings } from '@style/styled.js';
import SliderCardBlue from '@images/card-blue.webp';
import SliderCardWhite from '@images/card-white.webp';
import config from './config/env.json';

function ModalStartCardRecharge({ onClose, card_accounts, closeModal, url_card_id, UserAccountNumberFrom, UserAccountIDFrom }) {
  const [ModalStepNumber, setModalStepNumber] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [isProcessing, setIsProcessing] = useState(false);
  const inputsRef = useRef([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const step3_submitButtonRef = useRef(null);
  const navigate = useNavigate();
  const [transferAmount, setTransferAmount] = useState('');
  const [bank_account_balance, setbank_account_balance] = useState(null);
  const hasInsufficientFunds = bank_account_balance !== null && (bank_account_balance - italian_currency_to_number(transferAmount) - config.CARD_RECHARGE_FEES) < 0;
  const amountInputRef = useRef(null);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const styles = {
    step_description: {
      paddingBottom: '20px'
    },
  };

  const isAmountValid = () => {
    const amount = italian_currency_to_number(transferAmount);
    return !isNaN(amount) && amount > 0;
  };

  useEffect(() => {
    const isAmountValid = () => {
      const amount = italian_currency_to_number(transferAmount);
      return !isNaN(amount) && amount > 1;
    };

    const isValid = isAmountValid();
    setIsNextButtonEnabled(isValid && !isProcessing);
  }, [transferAmount, isProcessing]);


  const restart_recharge = () => {
    setErrorMessage('');
    setSuccessMessage('');
    setModalStepNumber(1);
  };

  const step2_review_recharge_details = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsProcessing(true);
    const [balance] = await Promise.all([
      fetchBankAccountBalance(UserAccountIDFrom)
    ]);
    setIsProcessing(false);
    // Update the state with the fetched data
    setbank_account_balance(balance);
    setModalStepNumber(2);
  };

  const step3_handlebanktranfer = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsProcessing(true);
    const token = getCookieValue('firebaseToken');

    try {
      const API_URL = await get_api_url();
      const response = await fetch(`${API_URL}/api/baas/transfer/bank_account_to_card`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          from: UserAccountNumberFrom,
          card_id: url_card_id,
          amount: italian_currency_to_number(transferAmount).toFixed(2),
          currency: 'EUR',
        })
      });


      if (!response.ok) {
        if (response.status === 500) {
          throw new Error("unexpected_error");
        }
        if (response.status === 429) {
          throw new Error("pleasewait30secs");
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalStepNumber(3);
      setSuccessMessage(t('recharge_initiated_successfully'));
    } catch (error) {
      const errorMsg = error.message === "too_many_requests_try_later" ? t("too_many_requests_try_later") : t(error.message);
      setErrorMessage(errorMsg);
      setSuccessMessage('');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      // Set the transferAmount state with the selected value
      setTransferAmount(newValue.value.toString());
    } else {
      // Reset the transferAmount state if nothing is selected
      setTransferAmount('');
    }
  };

  useEffect(() => {
    if (card_accounts.length === 0) {
      closeModal();
      navigate('/bank-transfers/create-beneficiary');
    }
  }, [card_accounts]);

  useEffect(() => {
    const isValid = isAmountValid(); // Assuming isAmountValid checks the validity of the input
    setIsNextButtonEnabled(isValid && !isProcessing);
  }, [transferAmount, isProcessing]); // Depend on transferAmount and isProcessing


  useEffect(() => {
    if (ModalStepNumber === 3 && inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }
  }, [ModalStepNumber, inputsRef]);

  useEffect(() => {
    fetchBankAccountBalance(UserAccountIDFrom).then((data) => {
      setbank_account_balance(data);
    });
  }, [UserAccountIDFrom]);

  // card selection if the link is from homepage
  if (url_card_id === 'select-card') {
    return (
      <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
        <ModalTitle>
          {t('card_to_recharge')}
        </ModalTitle>
        <DialogContent>
          <Box sx={{ width: "550px", height: "300px", paddingTop: '30px' }}>
            <Slider {...SliderSettings(currentSlide, setCurrentSlide)}>
              {card_accounts.map((card, index) => (
                <Box
                  key={index}
                  sx={{
                    cursor: "pointer",
                    position: "relative",
                    textAlign: 'center',
                    display: 'flex !important', // Add flex display
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                    paddingBottom: '30px', // Add some padding for spacing
                    paddingTop: '0px', // Add some padding for spacing
                  }}
                >
                  <Box style={{
                    position: 'relative',
                    width: '80%', // Container width
                    maxWidth: '400px', // Maximum width if needed
                    margin: '0 auto', // Center the container
                  }}>
                    {
                      card.current_status === "ACTIVE" ? (
                        <Box style={{
                          color: '#3dc690',
                          position: "absolute",
                          bottom: "25px",
                          left: "50%", // Center horizontally
                          transform: "translateX(-50%)", // Offset by half width
                          zIndex: 10,
                          fontSize: '1rem',
                          width: '100px',
                          textAlign: 'center',
                          lineHeight: '26px'
                        }}>
                          {t('card_active')}
                        </Box>
                      ) : card.current_status === "FDT_INITIATED" || card.current_status === null || card.current_status === 'ISSUE_PENDING' ? (
                        <Box style={{
                          color: '#aaaaaa',
                          position: "absolute",
                          bottom: "25px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          zIndex: 10,
                          fontSize: '1rem',
                          width: '100px',
                          textAlign: 'center',
                          lineHeight: '26px'
                        }}>
                          {t('card_being_created')}
                        </Box>
                      ) : (
                        <Box style={{
                          color: '#e34542',
                          position: "absolute",
                          bottom: "25px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          zIndex: 10,
                          fontSize: '1rem',
                          width: '100px',
                          textAlign: 'center',
                          lineHeight: '26px'
                        }}>
                          {t('card_inactive')}
                        </Box>
                      )
                    }
                    <img
                      style={{
                        width: "100%", // Take full width of container
                        margin: '0 auto', // Center the image
                        cursor: "pointer",
                        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.2)",
                        borderRadius: '17px',
                        display: 'block', // Remove any inline spacing
                      }}
                      src={card.card_color === 'blue' ? SliderCardBlue : SliderCardWhite}
                      alt="card img"
                    />
                    <Box
                      style={{
                        position: "absolute",
                        bottom: "70px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        zIndex: 55,
                        color: "#333333",
                        width: '100%', // Full width for text centering
                        textAlign: 'center'
                      }}
                    >
                      {card.card_last_four_digits ? '**** **** **** ' + card.card_last_four_digits : '**** **** **** ' + card.card_last_four_digit}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            ref={step3_submitButtonRef}
            onClick={closeModal}
            variant="contained"

            disabled={isProcessing}
          >
            {isProcessing ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t('close')
            )}
          </Button>
          <Button
            ref={step3_submitButtonRef}
            onClick={() => {
              const currentCard = card_accounts[currentSlide];
              if (currentCard) {
                navigate(`/cards/recharge/${currentCard.card_id}`);
              }
            }}
            variant="contained"

            disabled={isProcessing}
          >
            {isProcessing ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t('next_button')
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }


  switch (ModalStepNumber) {
    case 2:
      return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
          <ModalTitle>
            {t('review_recharge_details')}
          </ModalTitle>
          <DialogContent>
            {errorMessage && (
              <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
            )}
            {successMessage &&
              <SuccessContainer isModal={true}>{successMessage}</SuccessContainer>
            }
            <Table style={{ width: '100%' }}>
              <TableBody>
                <TableCell >
                  <span>{t('recharge_amount')}: </span>
                  <span>{number_to_italian_currency(transferAmount)}</span>
                </TableCell>
                <TableRow>
                  <TableCell >
                    <span>{t('recharge_fee')}: </span>
                    <span> {number_to_italian_currency(config.CARD_RECHARGE_FEES)}</span>
                  </TableCell> {/* Display the saved transfer description */}
                </TableRow>
                <TableRow>
                  <TableCell >
                    <span>{t('available_balance_bank_account')}: </span>
                    <span> {number_to_italian_currency(bank_account_balance)}</span>
                  </TableCell> {/* Display the saved transfer description */}
                </TableRow>
                <TableRow>
                  <TableCell >
                    <span>{t('available_balance_bank_account_post_recharge')}: </span>
                    <span
                      style={{
                        color: (bank_account_balance - italian_currency_to_number(transferAmount) - config.CARD_RECHARGE_FEES) < 0 ? 'red' : 'inherit'
                      }}
                    >
                      {number_to_italian_currency(bank_account_balance - italian_currency_to_number(transferAmount) - config.CARD_RECHARGE_FEES)}
                    </span>
                  </TableCell> {/* Display the saved transfer description */}
                </TableRow>

              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => restart_recharge()} // Corrected function call
              variant="contained"
              disabled={isProcessing}
            >
              {t('edit_button')}
            </Button>
            <Button
              onClick={step3_handlebanktranfer}
              variant="contained"

              disabled={hasInsufficientFunds || isProcessing}
            >
              {hasInsufficientFunds ? t('not_enough_funds') :
                isProcessing ? <CircularProgress size={24} color="inherit" /> :
                  t('start_card_recharge')}
            </Button>
          </DialogActions>
        </Dialog>
      );
    case 3:
      return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
          <ModalTitle>
            {t('recharge_status')}
          </ModalTitle>
          <DialogContent>
            {errorMessage && (
              <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
            )}
            {successMessage &&
              <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
            }
            {isProcessing ? (
              <Box style={styles.step_description}>
                {t('recharge_in_progress_do_not_close_this_window')}
              </Box>
            ) : (
              <Box style={styles.step_description}>
                {t('you_can_close_this_window')}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              ref={step3_submitButtonRef}
              onClick={closeModal}
              variant="contained"

              disabled={isProcessing}
            >
              {isProcessing ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t('close')
              )}
            </Button>
          </DialogActions>
        </Dialog>
      );
    default:
      return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
          <ModalTitle>
            {t("choose_recharge_amount")}
          </ModalTitle>
          <DialogContent>
            {errorMessage && (
              <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
            )}
            {successMessage && (
              <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
            )}

            <Box style={styles.step_description}>
              {t("choose_recharge_amount_description")}
            </Box>
            <Box style={styles.step_description}>
              {`${t("card_number")}: **** **** **** ${card_accounts.filter((e) => e.card_id === url_card_id)[0]
                .card_last_four_digits
                }`}
            </Box>
            <NumericFormat
              value={transferAmount}
              thousandSeparator={"."}
              decimalSeparator={","}
              isNumericString={true}
              suffix={" " + getCurrencySymbol("EUR")}
              customInput={TextField}
              label={t("recharge_amount")}
              variant="outlined"
              name="transfer_amount"
              decimalScale={2}
              fullWidth
              inputRef={amountInputRef}
              allowNegative={false}
              onValueChange={(values) => {
                const { value } = values;
                // Directly set the state with the numeric value
                setTransferAmount(value); // This will trigger the useEffect hook
              }}
            />
            <Box sx={{ marginTop: "16px" }}>
              <span>{t("account_balance_title")}: </span>
              {bank_account_balance ? (
                <span>
                  {" "}
                  {number_to_italian_currency(bank_account_balance)}
                </span>
              ) : (
                <CircularProgress size={16} color="inherit" />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={step2_review_recharge_details}
              variant="contained"
              disabled={isProcessing || !isNextButtonEnabled} // Use the state variable directly
            >
              {isProcessing ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("next_button")
              )}
            </Button>
          </DialogActions>
        </Dialog>
      );
  }
}

export default ModalStartCardRecharge;