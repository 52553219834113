import React, { useState, useEffect, useRef } from 'react';

import { Link, Box, Grid2, Card, CardContent, Typography, IconButton, ThemeProvider, Modal, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import GavelIcon from '@mui/icons-material/Gavel';
import DevicesIcon from '@mui/icons-material/Devices';
import LockIcon from '@mui/icons-material/Lock';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { getCookieValue } from './App';
import ModalUpdateEmail from './ModalUpdateEmail';
import ModalUpdatePhone from './ModalUpdatePhone';
import ModalUpdateResidentialAddress from './ModalUpdateResidentialAddress';
import ModalUpdatePassword from './ModalUpdatePassword';
import Loading from './Loading';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchPageData, isBusinessAccount } from './Util_API_calls'; // Adjust the path as necessary
import { t } from './Util_format';
import { useNavigate } from 'react-router-dom';
import { get_api_url } from './Util_API_calls';
import { getDisplayName } from './Util_app';
import { theme, PageTitle, list_with_bottom_border, updatePasswordStyle, TermsModal } from '@style/styled.js';

const initialDetails = {}

function PageProfile({ onDataChange, ...props }) {
  const isMobile = useMediaQuery('(max-width:768px)');
  const [details, setDetails] = useState(initialDetails);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const inputsRef = useRef([]);
  const [otp] = useState(Array(6).fill(""));
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeSessions, setActiveSessions] = useState([]);
  const [currentUserAddress, setCurrentUserAddress] = useState(null);
  const [editField, setEditField] = useState(null);
  const navigate = useNavigate();
  const [fadingSessionId, setFadingSessionId] = useState(null);

  // for modal
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [htmlContent, setHtmlContent] = useState("");
  const currentLanguage = getCookieValue("language");


  const handleEditClick = (field) => {
    setEditField(field);
    setIsModalOpen(true);
  };

  const handleSave = (field, newValue) => {
    setDetails({ ...details, [field]: newValue });
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setEditField(null);
    setIsModalOpen(false);
  };


  const fetchHtmlForModal = (routeName) => {
    const terms_url =
      currentLanguage === "en"
        ? `./docs/${routeName}-en${routeName === "termini" && isBusinessAccount() ? "-business" : ""}.html`
        : `./docs/${routeName}-it${routeName === "termini" && isBusinessAccount() ? "-business" : ""}.html`;

    fetch(terms_url) // Adjust the path if necessary
      .then((response) => response.text())
      .then((content) => {
        setHtmlContent(content);
        setOpen(true);
      });
  };


  const downloadPdf = (fileName) => {
    const terms_url =
      currentLanguage === "en"
        ? `./docs/${fileName}-en${fileName === "termini" && isBusinessAccount() ? "-business" : ""}.pdf`
        : `./docs/${fileName}-it${fileName === "termini" && isBusinessAccount() ? "-business" : ""}.pdf`;

    const downloadLink = document.createElement('a');
    downloadLink.href = terms_url; // Replace with your actual file path
    downloadLink.download = `${terms_url}.pdf`;
    downloadLink.click();
  };

  const get_page_data = async () => {
    const token = getCookieValue('firebaseToken');
    setIsLoading(true);
    try {
      const apidata = await fetchPageData('profile');
      if (apidata.status === 'error') {
        if (apidata.fdt_error_code === 'TOKEN_EXPIRED') {
          navigate('/login?action=clean-cookie&reason=session_expired');
          return;
        }
        if (apidata.fdt_error_code === 'NO_BUSINESS_ACCOUNT_FOUND') {
          alert(t('error_no_business_account_found'));
        } else {
          alert(t('error_getting_data_from_server'));
        }
        return;
      }
      const data = apidata.data;
      // After setting the state, call the onDataChange with the new data to update the notification bell
      if (onDataChange) {
        onDataChange(data);
      }

      setActiveSessions(data.sessions.mobile);
      setUserData(data); // Set the fetched data to userData
      // Update the details with the fetched user data
      const name = getDisplayName(data.details);
      setDetails({
        name_and_surname: name,
        //    date_of_birth: data.details.dob || '',
        email: data.details.email || '',
        phone: data.details.phone || '',
        address: `${data.details.addr_line_1}${data.details.addr_line_2 ? `, ${data.details.addr_line_2}` : ''}${data.details.zip_code ? `, ${data.details.zip_code}` : ''}${data.details.city ? `, ${data.details.city}` : ''}${data.details.state ? `, ${data.details.state}` : ''}${data.details.country ? `, ${data.details.country}` : ''}`,
        kyc_status: t('completed') || '',
      });
      setCurrentUserAddress(data.details);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const killSession = async (sessionId) => {
    const token = getCookieValue('firebaseToken');

    try {
      const API_URL = await get_api_url();
      const response = await fetch(`${API_URL}/api/auth/session/kill`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${token}`
        },
        body: `session_id=${encodeURIComponent(sessionId)}`
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Trigger fade out
      setFadingSessionId(sessionId);

      // Remove from state after animation
      setTimeout(() => {
        setActiveSessions(currentSessions => {
          if (!Array.isArray(currentSessions)) {
            return [];
          }
          return currentSessions.filter(session => session.session_id !== sessionId);
        });
        setFadingSessionId(null);
      }, 300);

    } catch (error) {
      console.error('Error killing session:', error);
    }
  };


  useEffect(() => {
    get_page_data();

  }, []);

  if (isLoading) {
    return <Loading></Loading>;
  }

  function EditDialog({ field, details, isOpen, onClose, onSave }) {
    const [value, setValue] = useState(details[field] || '');
    useEffect(() => {
      setValue(details[field] || '');
    }, [field, details]);

    useEffect(() => {
      inputsRef.current = inputsRef.current.slice(0, otp.length);
    }, []);

    if (!isOpen) {
      return null;
    }

    switch (field) {
      case 'phone':
        return <ModalUpdatePhone value={value} onSave={onSave} onClose={onClose} />;
      case 'email':
        return <ModalUpdateEmail value={value} onSave={onSave} onClose={onClose} />;
      case 'address':
        if (details && details.address) {
          return <ModalUpdateResidentialAddress onSave={onSave} onClose={onClose} address={currentUserAddress} />;
        }
      case 'password':
        return <ModalUpdatePassword value={value} onSave={onSave} onClose={onClose} />;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {isModalOpen && (
        <EditDialog
          field={`${editField}`}
          details={details}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSave={handleSave}
        />
      )}

      <Modal
        open={open}
        onClose={handleClose}
        sx={{ width: "100%", minWidth: 400 }}
      >
        <TermsModal>
          <Box
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
          <Button
            sx={{ marginTop: "30px" }}
            variant="contained"
            onClick={handleClose}
          >
            {t("close")}
          </Button>
        </TermsModal>
      </Modal>

      <Grid2 container rowSpacing={5} columnSpacing={2}>
        {/* First row */}
        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
          <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px', height: '100%' }}>
            <CardContent>
              <Grid2>
                <PageTitle>{t("personal_details")}<PersonIcon /></PageTitle>
              </Grid2>
              {Object.entries(details).map(([key, value]) => (
                <Box key={key} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '0.5rem' }}>
                  <Typography style={{ ...list_with_bottom_border }}>
                    <>{`${key !== "name_and_surname" ? t(key) : isBusinessAccount() ? t("business_name") : t(key)}`}</>: {value}
                  </Typography>
                  {key === 'email' && (
                    <IconButton onClick={() => handleEditClick('email')} size="small">
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  )}
                  {key === 'phone' && (
                    <IconButton onClick={() => handleEditClick('phone')} size="small">
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  )}
                  {key === 'address' && (
                    <IconButton onClick={() => handleEditClick('address')} size="small">
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
          <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px', height: '100%' }}>
            <CardContent>
              <Grid2>
                <PageTitle>{t("legal")}<GavelIcon /></PageTitle>
              </Grid2>
              <Grid2
                container
                spacing={6}
                sx={{
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {[
                  { title: "terms_and_conditions", id: "termini" },
                  { title: "privacy_and_cookies", id: "privacy_policy" },
                  { title: "google_pay_tnc", id: "google_pay" },
                  { title: "apple_pay_tnc", id: "apple_pay" }
                ].map((item) => (
                  <Grid2
                    key={item.id}
                    size={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Typography>
                      {t(item.title)}
                    </Typography>

                    <Link onClick={() => downloadPdf(item.id)}>
                      <FileDownloadIcon style={{
                        fontWeight: 500,
                        color: '#333333',
                        fontSize: '1.5rem',
                        fontFamily: 'Poppins',
                        padding: '10px',
                        cursor: 'pointer',
                      }} />
                    </Link>

                    <Button
                      style={{
                        ...updatePasswordStyle,
                        textTransform: "capitalize",
                        justifyContent: "center",
                        textDecoration: "none",
                        textAlign: "center",
                        border: "none",
                        outline: "none",
                        width: "120px"
                      }}
                      type="button"
                      onClick={() => fetchHtmlForModal(item.id)}
                    >
                      {t("view")}
                    </Button>
                  </Grid2>
                ))}
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>

        {/* Second row */}
        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
          <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px', height: '100%' }}>
            <CardContent>
              <Grid2>
                <PageTitle>{t("active_sessions_mobile")}<DevicesIcon /></PageTitle>
              </Grid2>
              {activeSessions.length > 0 ? activeSessions.map(session => (
                <Box
                  key={session.session_id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '10px',
                    transition: 'opacity 0.3s ease',
                    opacity: fadingSessionId === session.session_id ? 0 : 1,
                  }}
                >
                  <Typography style={{ ...list_with_bottom_border }}>
                    {session.device_name || 'Unknown Device'} - {session.device_model || 'Unknown Model'}
                  </Typography>
                  <IconButton onClick={() => killSession(session.session_id)} size="small">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )) : <Typography style={{ ...list_with_bottom_border }}>
                {t("no_active_sessions")}
              </Typography>}
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
          <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px', height: '100%' }}>
            <CardContent>
              <Grid2>
                <PageTitle>{t("security_settings")}<LockIcon /></PageTitle>
              </Grid2>
              <Typography style={updatePasswordStyle} onClick={() => handleEditClick('password')}>
                <VpnKeyIcon style={{ marginRight: '8px' }} /> {t('update_password_button')}
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>

    </ThemeProvider>
  );
}

export default PageProfile;