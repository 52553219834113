import KeyIcon from "@mui/icons-material/Key";
import LockIcon from "@mui/icons-material/Lock";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useCallback, useMemo, useState } from "react";
import { get_api_url } from './Util_API_calls';
import { Delete, PauseCircleFilled, PlayCircleFilled, } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, IconButton, Typography, } from "@mui/material";
import { getCookieValue } from "./App";
import { fetchCardIframeURL, fetchCardSenstiveData } from "./Util_API_calls";
import { formatCardNumber, t } from "./Util_format";
import SliderCardBlue from '@images/card-blue.webp';
import SliderCardWhite from '@images/card-white.webp';
import {ModalTitle, ErrorContainer, list_with_bottom_border } from '@style/styled.js';

function ModalStartCardEdit({
  closeModal,
  onClose,
  cardId,
  card_current_status,
  card_accounts,
}) {
  const [cardStatus, setCardStatus] = useState(card_current_status);
  const [isConfirmDeleteCard, setConfirmDeleteCard] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [iframeUrl, setIframeUrl] = useState(""); // New state for the iframe URL
  const [cardSecrets, setCardSecrets] = useState();
  const cardData = card_accounts.find((card) => card.card_id === cardId);

  const underlined_item_with_icon = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "10px",
    transition: "opacity 0.3s ease, max-height 0.3s ease", // You can also define this in your CSS
    opacity: 1, // Start with an opaque element
    maxHeight: "200px", // Adjust as needed, must be greater than the actual height
    cursor: "pointer",
  };

  const iframeStyle = {
    width: "100%",
    height: "400px", // Adjust the size as needed
  };

  const loadingContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "400px", // Same as iframe height
  };

  const handleCardOperation = async (operationType) => {
    try {
      setIsProcessing(true);
      if (operationType === "SENSITIVE_DATA") {
        const response = await fetchCardSenstiveData(cardId);
        setCardSecrets(response.details);
      } else {
        const response = await fetchCardIframeURL(cardId, operationType);
        const url = response.data.link;
        setIframeUrl(url); // Set the iframe URL
      }
    } catch (error) {
      setErrorMessage(t("error_general"));
    } finally {
      setIsProcessing(false);
    }
  };

  const isCardActive = useMemo(() => cardStatus === "ACTIVE", [cardStatus]);

  const onCardStatusChange = useCallback(
    async (isActionDelete) => {
      try {
        setIsProcessing(true);
        const newStatus = isActionDelete
          ? "TERMINATED"
          : isCardActive
            ? "BLOCKED"
            : "ACTIVE";
        const token = getCookieValue("firebaseToken");
        const API_URL = await get_api_url();
        await fetch(`${API_URL}/api/baas/cards/card_change_status`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            card_id: cardId,
            status: newStatus,
          }),
        }
        );
        cardData.current_status = newStatus;
        setCardStatus(newStatus);
        if (isActionDelete) {
          closeModal();
        }
      } catch (error) {
        setErrorMessage(t("error_general"));
      } finally {
        setIsProcessing(false);
      }
    },
    [cardData, cardId, closeModal, isCardActive]
  );

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <ModalTitle>
        {t("card_operations_title")}
      </ModalTitle>
      {errorMessage && (
        <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
      )}

      <DialogContent>
        {isProcessing ? (
          <Box style={loadingContainerStyle}>
            <CircularProgress />
          </Box>
        ) : cardSecrets ? (
          <Box sx={{ position: "relative" }}>
            <img
              style={{
                width: "calc(100% - 20px)",
                paddingRight: "10px",
                paddingLeft: "10px",
                cursor: "pointer",
              }}
              src={
                cardData.card_color === "blue"
                  ? SliderCardBlue
                  : SliderCardWhite
              }
              alt="card img"
            />
            <Box
              style={{
                position: "absolute",
                bottom: "35%",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 55,
                color: "#333333",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {formatCardNumber(cardSecrets.card_number)}
            </Box>
            <Box
              style={{
                position: "absolute",
                bottom: "24px",
                left: "48px",
                zIndex: 56,
                color: "#333333",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "8px",
                }}
              >
                <Box>CVV: {cardSecrets.security_password}</Box>
                <Box
                  style={{
                    marginLeft: "42px",
                  }}
                >
                  {cardSecrets.expiration_date}
                </Box>
              </Box>

              <Box>{cardSecrets.cardholder_name}</Box>
            </Box>
          </Box>
        ) : iframeUrl ? (
          <iframe src={iframeUrl} style={iframeStyle} frameBorder="0"></iframe>
        ) : null}
        {/* Rest of your content */}
      </DialogContent>
      <DialogContent>
        <Box style={{ paddingTop: "20px" }}>
          <Box
            style={{
              ...underlined_item_with_icon,
              cursor: `${cardStatus !== "INACTIVE" ? "" : "pointer"}`,
            }}
            onClick={() => {
              if (cardStatus !== "INACTIVE") return;
              handleCardOperation("ACTIVATE_CARD");
            }}
          >
            <Typography
              style={{
                ...list_with_bottom_border,
                color: `${cardStatus !== "INACTIVE" ? "#C6CCD0" : "#8ECAE6"}`,
              }}
            >
              {t("ACTIVATE_CARD")}
            </Typography>
            <IconButton
              size="small"
              disabled={cardStatus !== "INACTIVE"}
              color={`${cardStatus !== "INACTIVE" ? "#C6CCD0" : "#8ECAE6"}`}
            >
              <PowerSettingsNewIcon />
            </IconButton>
          </Box>
          {(isCardActive || cardStatus === "BLOCKED") && (
            <Box
              style={{ ...underlined_item_with_icon }}
              onClick={() => handleCardOperation("SENSITIVE_DATA")}
            >
              <Typography style={{ ...list_with_bottom_border }}>
                {t("SENSITIVE_DATA")}
              </Typography>
              <IconButton size="small">
                <VisibilityOffIcon />
              </IconButton>
            </Box>
          )}
          {(isCardActive || cardStatus === "BLOCKED") && (
            <Box
              style={{ ...underlined_item_with_icon }}
              onClick={() => onCardStatusChange(false)}
            >
              <Typography style={{ ...list_with_bottom_border }}>
                {t(isCardActive ? "block_card" : "reactive_card")}
              </Typography>
              <IconButton size="small">
                {isCardActive ? <PauseCircleFilled /> : <PlayCircleFilled />}
              </IconButton>
            </Box>
          )}
          {(isCardActive || cardStatus === "BLOCKED") && (
            <Box
              style={{ ...underlined_item_with_icon }}
              onClick={() => handleCardOperation("CHANGE_PIN")}
            >
              <Typography style={{ ...list_with_bottom_border }}>
                {t("CHANGE_PIN")}
              </Typography>
              <IconButton size="small">
                <LockIcon />
              </IconButton>
            </Box>
          )}
          {(isCardActive || cardStatus === "BLOCKED") && (
            <Box
              style={{ ...underlined_item_with_icon }}
              onClick={() => handleCardOperation("SET_STATIC_PASSWORD")}
            >
              <Typography style={{ ...list_with_bottom_border }}>
                {t("SET_WEB_PASSWORD")}
              </Typography>
              <IconButton size="small">
                <KeyIcon />
              </IconButton>
            </Box>
          )}
          {(isCardActive || cardStatus === "BLOCKED") && (
            <Box
              style={{ ...underlined_item_with_icon }}
              onClick={() => handleCardOperation("CHANGE_STATIC_PASSWORD")}
            >
              <Typography style={{ ...list_with_bottom_border }}>
                {t("CHANGE_WEB_PASSWORD")}
              </Typography>
              <IconButton size="small">
                <RefreshIcon /> {/* Replace with PasswordIcon if available */}
              </IconButton>
            </Box>
          )}
          {(isCardActive || cardStatus === "BLOCKED") && (
            <Box
              style={{ ...underlined_item_with_icon }}
              onClick={() => setConfirmDeleteCard(true)}
            >
              <Typography style={{ ...list_with_bottom_border }}>
                {t("delete_card")}
              </Typography>
              {isConfirmDeleteCard ? (
                <Button
                  onClick={() => onCardStatusChange(true)}
                  color="warning"
                  size="small"                >
                  {t("confirm")}
                </Button>
              ) : (
                <IconButton size="small">
                  <Delete />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => closeModal()}
          variant="contained"          
          disabled={isProcessing}
        >
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalStartCardEdit;