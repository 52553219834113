import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { formatString, number_to_italian_nosymbol, t } from './Util_format';
import { getDisplayName } from './Util_app';
import logo_big from '@images/logo_big.png';

const generatePDF = (account_type, transactions, selectedMonthYear, pageData, info) => {
  const isStatement = typeof selectedMonthYear !== 'object';
  const isCardTransaction = account_type === "card";

  const hasTransactions = Array.isArray(transactions) && transactions.length > 0;
  var sortedFilteredTransactions;

  if (hasTransactions) {
    // Convert transaction dates to Date objects and format them
    const processedTransactions = transactions.map((tx) => {
      let dateKey = isCardTransaction
        ? "transaction_created_at"
        : "transaction_date_time";
      const date = new Date(tx[dateKey]);
      let description = isCardTransaction
        ? tx.merchant_name
          ? `${t(tx.transaction_type)} ${tx.merchant_name}`.toLowerCase()
          : t(tx.transaction_type).toLowerCase()
        : tx.description;

      return {
        ...tx,
        date: !isNaN(date.getTime())
          ? format(date, "dd/MM/yyyy HH:mm", { locale: it })
          : "Invalid Date",
        originalDate: date,
        description: description,
        amount: number_to_italian_nosymbol(tx.amount),
      };
    });

    // Filter transactions based on the selectedMonthYear using 'yyyy-MM' format
    const filteredTransactions = isStatement
      ? processedTransactions.filter((tx) => {
          const transactionMonthYear = format(tx.originalDate, "yyyy-MM");
          return transactionMonthYear === selectedMonthYear;
        })
      : processedTransactions;

    if (filteredTransactions.length === 0) {
      console.error("No transactions found for the selected month and year.");
      return;
    }

    sortedFilteredTransactions = filteredTransactions.sort(
      (a, b) => a.originalDate - b.originalDate
    );
  }

  // Reduced font sizes
  const headerFontSize = 14;
  const header2FontSize = 10;
  const bodyFontSize = 9;
  
  const selectedMonthYearFormatted = isStatement 
    ? format(new Date(selectedMonthYear + '-01'), 'MMMM yyyy', { locale: it }) 
    : `${format(selectedMonthYear.from, 'dd/MM/yyyy')} - ${format(selectedMonthYear.to, 'dd/MM/yyyy')}`;
  
  const pdf = new jsPDF();

  // Add Poppins font to the PDF
  pdf.addFileToVFS('Poppins-Regular.ttf');
  pdf.addFont('Poppins-Regular.ttf', 'Poppins', 'normal');

  // Reduced logo size
  const logoWidthMM = 510 * 0.08;
  const logoHeightMM = 510 * 0.08;

  var columns, summaryColumns, summaryBody;
  if (hasTransactions) {
    columns = [
      { header: t('date'), dataKey: 'date' },
      { header: t('description'), dataKey: 'description' },
      { header: `${t('amount')} €`, dataKey: 'amount' },
    ];
  
    summaryColumns = [
      { header: t('total_incoming'), dataKey: 'incoming' },
      { header: t('total_outgoing'), dataKey: 'outgoing' },
      { header: t('currency'), dataKey: 'currency' },
    ];
  
    const incoming = isCardTransaction
      ? transactions
          .filter(e => e.amount && e.direction && e.direction.toUpperCase() === "CREDIT")
          .reduce((sum, { amount }) => sum + amount, 0)
      : transactions
          .filter(e => e.amount && 
            (e.transaction_type.toUpperCase() === "FUNDING" || 
             e.transaction_type.toUpperCase() === "EXCHANGE"))
          .reduce((sum, { amount }) => sum + amount, 0);
  
    const outgoing = isCardTransaction
      ? transactions
          .filter(e => e.amount && e.direction && e.direction.toUpperCase() === "DEBIT")
          .reduce((sum, { amount }) => sum + amount, 0)
      : transactions
          .filter(e => e.amount && 
            (e.transaction_type.toUpperCase() === "TRANSFER" || 
             e.transaction_type.toUpperCase() === "INVOICE"))
          .reduce((sum, { amount }) => sum + amount, 0);
  
    summaryBody = [
      {
        incoming: number_to_italian_nosymbol(incoming),
        outgoing: number_to_italian_nosymbol(outgoing),
        currency: "EUR",
      },
    ];
  }

  // Function to wrap long text
  const wrapText = (text, maxWidth) => {
    const words = text.split(' ');
    let lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = pdf.getStringUnitWidth(currentLine + ' ' + word) * bodyFontSize;
      
      if (width < maxWidth) {
        currentLine += ' ' + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  };

  autoTable(pdf, {
    columns: columns,
    body: sortedFilteredTransactions,
    startY: logoHeightMM + 65 + (isStatement ? 45 : 0),
    margin: { top: 20 },
    didDrawPage: (data) => {
      const generatedTime = format(new Date(), 'hh:mm a, dd/MM/yyyy', { locale: it });

      if (pdf.internal.getNumberOfPages() === 1) {
        const pageWidth = pdf.internal.pageSize.getWidth();
        const centerX = (pageWidth - logoWidthMM) / 2;
        pdf.addImage(logo_big, 'PNG', centerX, 10, logoWidthMM, logoHeightMM);

        const headerText1 = isStatement 
          ? t('monthly_account_statement') + ' ' + selectedMonthYearFormatted 
          : `${t('transactions')} ${selectedMonthYearFormatted}`;

        pdf.setFontSize(headerFontSize);

        const name = getDisplayName(pageData.details);
        const address = `${pageData.details.addr_line_1}${pageData.details.addr_line_2 ? `, ${pageData.details.addr_line_2}` : ''}${pageData.details.zip_code ? `, ${pageData.details.zip_code}` : ''}${pageData.details.city ? `, ${pageData.details.city}` : ''}${pageData.details.state ? `, ${pageData.details.state}` : ''}${pageData.details.country ? `, ${pageData.details.country}` : ''}`;
        
        // Format header texts
        const headerText2 = `${t('account_owner_name')}: ${name}`;
        const headerText4 = isCardTransaction 
          ? `${t('card_number')}: **** **** **** ${info ?? ""}` 
          : `IBAN: ${info ?? ""}`;
        const headerText5 = `${t('generated_at')}: ${generatedTime}`;

        let currentY = 45;
        const lineSpacing = 8;
        
        // Draw main header
        pdf.setFontSize(headerFontSize);
        pdf.text(headerText1, 15, currentY);
        currentY += lineSpacing + 4;

        // Draw other header information
        pdf.setFontSize(header2FontSize);
        pdf.text(headerText2, 15, currentY);
        currentY += lineSpacing;

        // Handle address wrapping
        pdf.setFontSize(bodyFontSize);
        const maxWidth = pageWidth - 30; // 15mm margin on each side
        const wrappedAddress = wrapText(`${t('account_owner_address')}: ${address}`, maxWidth);
        wrappedAddress.forEach(line => {
          pdf.text(line, 15, currentY);
          currentY += lineSpacing;
        });

        pdf.text(headerText4, 15, currentY);
        currentY += lineSpacing;
        
        pdf.text(headerText5, 15, currentY);
        
        if (hasTransactions && isStatement) {
          currentY += lineSpacing * 1.5;
          pdf.setFontSize(headerFontSize);
          pdf.text(t("account_summary"), 15, currentY);

          autoTable(pdf, {
            columns: summaryColumns,
            body: summaryBody,
            startY: currentY + 5,
            margin: { top: 10 },
            styles: {
              fontSize: bodyFontSize,
              overflow: "linebreak",
            },
            headStyles: {
              fillColor: "#333333",
              textColor: "#FFFFFF",
              halign: "center",
              fontStyle: "bold",
            },
            columnStyles: {
              date: { cellWidth: 60 },
              amount: { cellWidth: 40, halign: "right" },
            },
            theme: "grid",
          });

          pdf.setFontSize(headerFontSize);
          pdf.text(t('transactions'), 15, currentY + 40);
        }
      } else {
        // Header for subsequent pages
        pdf.setFontSize(bodyFontSize);
        pdf.text(
          formatString(
            t(isStatement
              ? isCardTransaction
                ? "title_statement_header_card"
                : "title_statement_header_account"
              : isCardTransaction
                ? "title_transactions_header_card"
                : "title_transactions_header_account"
            ),
            {
              month: selectedMonthYearFormatted,
              number: info ?? "N/A",
              time: generatedTime,
            }
          ),
          15,
          15
        );
      }

      // Page numbers
      const str = t('page') + ' ' + pdf.internal.getNumberOfPages();
      const pageSize = pdf.internal.pageSize;
      const pageWidth = pageSize.getWidth ? pageSize.getWidth() : pageSize.width;
      pdf.setFontSize(bodyFontSize);
      pdf.text(str, pageWidth / 2, pageSize.height - 10, {
        align: 'center',
      });
    },
    styles: {
      fontSize: bodyFontSize,
      overflow: 'linebreak',
    },
    headStyles: {
      fillColor: '#333333',
      textColor: '#FFFFFF',
      halign: 'center',
      fontStyle: 'bold',
      fontSize: bodyFontSize + 1,
    },
    columnStyles: {
      date: { cellWidth: 60 },
      amount: { cellWidth: 40, halign: 'right' },
    },
    theme: 'grid',
    showHead: 'everyPage',
  });

  pdf.save('statement2.pdf');
};

export { generatePDF };