import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import SumsubWebSdk from "@sumsub/websdk-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookieValue } from "./App";
import config from "./config/env.json";
import { Box } from '@mui/material';
import Loading from "./Loading";
import { isUserPassedKyb, isUserPassedKyc, updateUserTenancies, } from "./Util_API_calls";
import { KycMainDiv, KycContent, KycCloseButton } from '@style/styled.js';

function Kyb() {
  const [accessToken, setAccessToken] = useState(null);
  const [userEmail, setUserEmail] = useState(null); // Added state for email
  const firebaseToken = getCookieValue("firebaseToken");
  const navigate = useNavigate();

  const isLoggedIn = getCookieValue("user_logged_in") === "true";
  const isPassedOtp = getCookieValue("login_otp_completed") === "true";

  useEffect(() => {
    if (config.ENABLE_BUSINESS !== "true") {
      if (getCookieValue("login_otp_completed") === "true") {
        navigate("/home");
      } else if (getCookieValue("user_logged_in") !== "true") {
        navigate("/login");
      } else if (getCookieValue('kyc_completed') !== "true") {
        navigate("/identification");
      }
      return;
    }
    async function fetchAccessToken() {
      try {
        if (!isLoggedIn) {
          navigate("/login");
          return;
        }

        const body = {};
        if (window.location.pathname.startsWith("/kyb/new")) {
          const name = window.location.pathname.split("/").pop();
          if (name.length > 0) {
            body.business_id = "new-application";
            body.business_name = name;
          } else if (isPassedOtp) {
            navigate("/home");
            return;
          } else {
            navigate("/login");
            return;
          }
        } else {
          const businessId = window.location.pathname.split("/").pop();
          body.business_id = businessId;
        }

        const response = await fetch(
          `${config.API_DOMAIN_BUSINESS}/api/baas/kyb/issue-token`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + firebaseToken,
            },
            body: JSON.stringify(body),
          }
        );

        const data = await response.json();
        if (data.fdt_error_code === "APPLICANT_DEACTIVATED_BY_SUMSUB") {
          if (isPassedOtp && (isUserPassedKyc() || isUserPassedKyb())) {
            navigate("/home");
          } else {
            navigate("/login?action=clean-cookie&reason=account-banned");
          }
          return;
        }

        if (response.status === 400) {
          if (isPassedOtp) {
            navigate("/home");
          } else {
            navigate("/login");
          }
        }

        if (response.status === 401) {
          navigate("/login?action=clean-cookie&reason=session_expired");
          return;
        }

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        if (data.userId && data.userId.trim().length > 0) {
          await updateUserTenancies();
          setAccessToken(data.token);
          setUserEmail(data.email); // Set the email state with the email from the API response
        } else {
          await new Promise((r) => setTimeout(r, 2000));
          fetchAccessToken();
        }

        setAccessToken(data.token);
        setUserEmail(data.email); // Set the email state with the email from the API response
      } catch (error) {
        console.error("There was a problem fetching the access token:", error);
      }
    }

    fetchAccessToken();
  }, [firebaseToken, isLoggedIn, navigate]);

  function handleMessage(messageType, payload) {
    // console.log('onMessage', messageType, payload);
    if (payload && payload.applicantId) {
      console.log("Applicant ID:", payload.applicantId);
    }
  }

  const accessTokenExpirationHandler = () => {
    // console.log('Access token expired');
  };

  const handleLogout = () => {
    if (isPassedOtp) {
      navigate("/home");
    } else {
      navigate("/login?action=clean-cookie&reason=user_logout");
    }
  };

  return (
    <KycMainDiv>
      <KycContent>
        <Box style={{ left: '50%', width: '100%' }}>
          {accessToken && userEmail ? ( // Also check for userEmail before rendering SumsubWebSdk
            <SumsubWebSdk
              accessToken={accessToken}
              expirationHandler={accessTokenExpirationHandler}
              config={{ email: userEmail }} // Pass the userEmail state here
              options={{ adaptIframeHeight: true }}
              onMessage={handleMessage}
              onError={(e) => console.log("onError", e)}
            />
          ) : (
            <Loading />
          )}
        </Box>
        <KycCloseButton>
          <IconButton onClick={handleLogout}>
            <CloseIcon />
          </IconButton>
        </KycCloseButton>
      </KycContent>
    </KycMainDiv>
  );
}

export default Kyb;
