import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import { Grid2, Card, CardContent, InputAdornment, Button, TextField, TableHead, Table, TableBody, TableCell, TableRow } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getCookieValue } from './App';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'; // Sample icon for 'Bank Transfer'
import { PeopleOutline } from '@mui/icons-material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import Loading from './Loading';
import ModalDeletebeneficiary from './ModalDeletebeneficiary';
import ModalCreatebeneficiary from './ModalCreatebeneficiary';
import ModalStartBankTransfer from './ModalStartBankTransfer';
import { useNavigate } from 'react-router-dom';
import { fetchPageData } from './Util_API_calls';
import { t } from './Util_format';
import { PageTitle, FDTTableSortLabel, FDTTableFooter } from '@style/styled.js';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';

function Page_Banktransfers({ onDataChange, ...props }) {
  let { url_beneficiary_id } = useParams();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:768px)'); // Mobile breakpoint
  const [isLoading, setIsLoading] = useState(true);
  const [isTransfering, setTransfering] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [UserAccountNumberFrom, setUserAccountNumberFrom] = useState('');
  const [UserAccountIDFrom, setUserAccountIDFrom] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [isDeleteModalOpen, setisDeleteModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isTransferDirect, setIsTransferDirect] = useState(false);
  const [tempBeneficiary, setTempBeneficiary] = useState(null);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [currentBeneficiary, setCurrentBeneficiary] = useState(null);
  const [details] = useState({});
  const [field] = useState('');
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const [Beneficiary_id_on_url, setBeneficiary_id_on_url] = useState(null);

  const renderSortLabel = (key, label) => (
    <FDTTableSortLabel
      active={sortConfig.key === key}
      direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
      onClick={() => requestSort(key)}
    >
      {label}
    </FDTTableSortLabel>
  );

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortValue = (item, key) => {
    switch (key) {
      case 'name':
        return `${item.beneficiary_name} ${item.beneficiary_surname}`.toLowerCase();
      case 'iban':
        return item.beneficiary_iban.toLowerCase();
      case 'currency':
        return item.beneficiary_currency.toLowerCase();
      default:
        return item[key];
    }
  };

  const [sortedBeneficiaries, setSortedBeneficiaries] = useState([...beneficiaries]); // Added a new state for the sorted beneficiaries

  // Define a function to handle send money action (this should be implemented accordingly)
  const handleSendMoney = (beneficiary_id) => {
    navigate('/bank-transfers/make-payment/' + beneficiary_id);
  };

  const handleDeleteClick = (beneficiary) => {
    setCurrentBeneficiary(beneficiary);
    setisDeleteModalOpen(true);
  };

  const handleCreateModalOpen = (isDirect) => {
    console.log(isDirect);
    setIsTransferDirect(isDirect);
    setIsCreateModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setIsTransferDirect(false);
    setTempBeneficiary(null);
    setIsCreateModalOpen(false);
  };

  const handleStartTransferModalOpen = (id) => {
    setBeneficiary_id_on_url(id); // Save the ID to state
    setIsTransferModalOpen(true);
  };

  const handleStartTransferModalClose = () => {
    setIsTransferDirect(false);
    setTempBeneficiary(null);
    setIsTransferModalOpen(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when changing the number of rows per page
  };

  const [searchQuery, setSearchQuery] = useState('');

  const get_page_data = async () => {
    setIsLoading(true);
    try {
      const apidata = await fetchPageData('bank-transfers');
      if (apidata.status === 'error') {
        if (apidata.fdt_error_code === 'TOKEN_EXPIRED') {
          navigate('/login?action=clean-cookie&reason=session_expired');
          return;
        }
        if (apidata.fdt_error_code === 'NO_BUSINESS_ACCOUNT_FOUND') {
          alert(t('error_no_business_account_found'));
        } else {
          alert(t('error_getting_data_from_server'));
        }
        return;
      }
      let data = apidata.data;
      if (onDataChange) {
        onDataChange(data);
      }

      setBeneficiaries(data.beneficiaries);
      // console.log();
      setUserAccountNumberFrom(data.user_accounts.current_accounts[0].account_number);
      setUserAccountIDFrom(data.user_accounts.current_accounts[0].account_id);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };


  const handleCloseTransferModal = () => {
    setIsTransferDirect(false);
    setTempBeneficiary(null);
    setIsTransferModalOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setIsTransferDirect(false);
    setTempBeneficiary(null);
    setisDeleteModalOpen(false);
  }

  const onTransferDirect = (beneficiary) => {
    setTempBeneficiary(beneficiary);
    setIsCreateModalOpen(false);
    setIsTransferModalOpen(true);
  }

  useEffect(() => {
    // Check if the current path starts with '/bank-transfers/create-beneficiary'
    if (location.pathname.startsWith('/bank-transfers/create-beneficiary')) {
      handleCreateModalOpen(false);
    } else if (location.pathname.startsWith('/bank-transfers/new')) {
      handleCreateModalOpen(true);
    }
    // Check if the current path starts with '/bank-transfers/make-payment'
    if (location.pathname.startsWith('/bank-transfers/make-payment')) {
      const pathSegments = location.pathname.split('/');
      const url_beneficiary_id = pathSegments[3]; // Gets the ID from the path
      handleStartTransferModalOpen(url_beneficiary_id);
    }
  }, [location]); // Depend on the location object so that the effect runs on location change 

  useEffect(() => {
    function sortAndFilterList(beneficiaries) {
      return beneficiaries
        .filter(beneficiary => {
          const searchTerm = searchQuery.toLowerCase();
          return (
            beneficiary.beneficiary_name.toLowerCase().includes(searchTerm) ||
            beneficiary.beneficiary_iban.toLowerCase().includes(searchTerm) ||
            beneficiary.beneficiary_surname.toLowerCase().includes(searchTerm)
          );
        })
        .sort((a, b) => {
          const aValue = getSortValue(a, sortConfig.key);
          const bValue = getSortValue(b, sortConfig.key);

          if (aValue < bValue) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
        });
    }

    setSortedBeneficiaries(sortAndFilterList(beneficiaries));
  }, [sortConfig, beneficiaries, searchQuery]);

  useEffect(() => {
    get_page_data();

  }, []);

  function DeletebeneficiaryDialog({ beneficiary, isOpen, onClose, onSave }) {
    const [value, setValue] = useState(details[field] || '');
    useEffect(() => {
      setValue(details[field] || '');
    }, [field, details]);

    if (!isOpen || !beneficiary) {
      return null;
    }
    return (
      <ModalDeletebeneficiary
        beneficiary={beneficiary}
        onDelete={() => {
          onClose(); // Close the modal after deletion
        }}
        onClose={onClose}
        closeDeleteModal={handleCloseDeleteModal}
      />
    );
  }

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <>
      {isCreateModalOpen && (
        <ModalCreatebeneficiary
          // ... other props
          isOpen={isCreateModalOpen}
          onClose={() => handleCreateModalClose()}
          isTransferDirect={isTransferDirect}
          onTransferDirect={onTransferDirect}
        />
      )}

      {isDeleteModalOpen && (
        <DeletebeneficiaryDialog
          beneficiary={currentBeneficiary}
          isOpen={isDeleteModalOpen}
          onClose={() => setisDeleteModalOpen(false)}
          closeDeleteModal={handleCloseDeleteModal}
        />
      )}
      {isTransferModalOpen && (
        <ModalStartBankTransfer
          isOpen={isTransferModalOpen}
          onClose={isTransfering ? null : handleStartTransferModalClose}
          beneficiaries={beneficiaries}
          url_beneficiary_id={url_beneficiary_id}
          closeModal={handleCloseTransferModal}
          UserAccountNumberFrom={UserAccountNumberFrom}
          UserAccountIDFrom={UserAccountIDFrom}
          tempBeneficiary={tempBeneficiary}
          setTransfering={setTransfering}
        />
      )}
      <Card>
        <CardContent>
          <Grid2>
            <PageTitle>{t("bank_transfers")}<TransferWithinAStationIcon /></PageTitle>
          </Grid2>
          <Grid2 container spacing={2} sx={{ mb: 4 }}>
            <Grid2 xs={12} sm={3}>
              <Button
                variant="contained"
                startIcon={<PersonOutlineIcon />}
                fullWidth
                onClick={() => handleCreateModalOpen(false)}
              >
                {t("create_beneficiary")}
              </Button>
            </Grid2>

            <Grid2 xs={12} sm={3}>
              <Button
                variant="contained"
                startIcon={<SendIcon />}
                fullWidth
                onClick={() => handleCreateModalOpen(true)}
              >
                {t("new_bank_transfer")}
              </Button>
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <TextField
                label={t("search_beneficiary")}
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid2>
          </Grid2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'medium' }}>{t('beneficiary_name')}</TableCell>
                {!isMobile && <TableCell sx={{ fontWeight: 'medium' }}>{t('beneficiary_iban')}</TableCell>}
                {!isMobile && <TableCell sx={{ fontWeight: 'medium' }}>{t('currency')}</TableCell>}
                <TableCell sx={{ fontWeight: 'medium' }}>{t('operations')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedBeneficiaries
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((beneficiary, index) => (
                  <TableRow key={index}>
                    <TableCell>{beneficiary.beneficiary_name} {beneficiary.beneficiary_surname}</TableCell>
                    {!isMobile && <TableCell>{beneficiary.beneficiary_iban.match(/.{1,4}/g).join(' ')}</TableCell>}
                    {!isMobile && <TableCell>{beneficiary.beneficiary_currency}</TableCell>}
                    <TableCell>
                      <IconButton onClick={() => handleDeleteClick(beneficiary)} size="small">
                        <IconButton
                          onClick={() => handleDeleteClick(beneficiary)}
                          size="small"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <DeleteIcon
                            style={{
                              color: isHovered ? 'red' : '#AAAAAA',
                              cursor: 'pointer',
                              verticalAlign: 'middle',
                              marginRight: '8px',
                            }}
                          />
                        </IconButton>
                      </IconButton>
                      <IconButton onClick={() => handleSendMoney(beneficiary.beneficiary_id)} size="small">
                        <SendIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <FDTTableFooter
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedBeneficiaries.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('rows_per_page')}
            labelDisplayedRows={
              ({ from, to, count }) => {
                return '' + from + '-' + to + ` ${t('of')} ` + count
              }
            }
          />
        </CardContent>
      </Card>
    </>
  );
}

export default Page_Banktransfers;