import { Box, TextField, Typography, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "./Util_format";
import { ErrorContainer, LoginLogoMobile } from '@style/styled.js';     

function BusinessIntroduction() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  return (
    <div>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          padding: "20px 0px"
        }}
      >
        <LoginLogoMobile />
        {t("open_your_business_account")}
      </Typography>
      <Typography>{t("description_business_account")}</Typography>
      <Box sx={{ mt: 3 }} />
      <Box style={{ textAlign: "left", overflow: "auto", maxHeight: "280px" }}>
        <Typography>• {t("company_name")}</Typography>
        <Typography>• {t("company_address")}</Typography>
        <Typography>• {t("business_sector_and_activities")}</Typography>
        <Typography>• {t("business_incorporation_date")}</Typography>
        <Typography>• {t("business_tax_number")}</Typography>
        <Typography>• {t("business_chamber_of_commerce_extract")}</Typography>
        <Typography>• {t("business_ownership_structure")}</Typography>
        <Typography>• {t("business_articles_of_association")}</Typography>
        <Typography>• {t("business_residential_address_evidence")}</Typography>

      </Box>
      <Box sx={{ mt: 3 }} />
      {t("what_is_your_company_name")}
      <TextField
        label={t("business_name")}
        id="business_name"
        variant="outlined"
        margin="normal"
        fullWidth
      />

      {error && (
        <ErrorContainer isModal={false}>{error}</ErrorContainer>
      )}
      <Box sx={{ mt: 2 }} />
      <Button
        variant="contained"
        
        onClick={() => {
          const businessName = document.getElementById("business_name").value;
          if (!businessName.trim()) {
            setError(t("message_error_empty_business_name"));
            return;
          }
          navigate(`/kyb/new/${businessName}`);
        }}
      >
        {t("continue_button")}
      </Button>
      <Typography sx={{ marginTop: '20px', fontSize: '12px' }}>* {t("note_process_takes_up_to_4_days")}</Typography>
      <Box
        component="div"
        onClick={() => navigate('/')}
        sx={{
          paddingTop: '10px',
          textDecoration: 'underline',
          cursor: 'pointer',
          color: '#777777',
          fontStyle: 'italic',
          '&:hover': {
            color: '#1976d2',
            transition: 'color 0.2s'
          }
        }}
      >
        {t("back_button")}
      </Box>
    </div>
  );
}

export default BusinessIntroduction;
