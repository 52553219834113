import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { Box, Grid, Card, CardContent, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button, createTheme, ThemeProvider, CircularProgress } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { getCookieValue, setCookieValue } from './App';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { t } from './Util_format';
import { theme, ModalTitle, ErrorContainer } from '@style/styled.js';
import { sendOTPDoubleAuth, verifyPushApproval } from './Util_OTP_doubleauth';
import { get_api_url } from './Util_API_calls';

function ModalUpdatePhone({ value, onSave, onClose }) {
  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t("update_phone_title")}
      </DialogTitle>
      <DialogContent>
        <Typography>{t("message_contact_to_update")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="contained" >
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
  
//   const [ModalStepNumber, setModalStepNumber] = useState(false);
//   const [otp, setOtp] = useState(Array(6).fill(""));
//   const [isProcessing, setIsProcessing] = useState(false);
//   const inputsRef = useRef([]);
//   const [errorMessage, setErrorMessage] = useState('');
//   const step2_submitButtonRef = useRef(null); // Add this line to create a ref for the submit button
//   const step4_submitButtonRef = useRef(null); // Add this line to create a ref for the submit button
//   const [userNewPhone] = useState(''); // Step 1: Initialize the new phone state
//   const [userNewPhone_stored] = useState(''); // Step 1: Initialize the new phone state
//   const PhoneDoubleAuthExp = getCookieValue('PhoneDoubleAuthExp'); // Make sure getCookieValue is defined and imported
//   const phoneInputRef = useRef();
//   const userNewPhoneRef = useRef('');
//   const navigate = useNavigate();
//   const intervalRef = useRef();
// 
//   useEffect(() => {
//     if (
//       ModalStepNumber === 2 &&
//       getCookieValue("double_auth_otp_delivery_method") === "push"
//     ) {
//       intervalRef.current = setInterval(async () => {
//         const isApproved = await verifyPushApproval(
//           "update_phone_mfa"
//         );
//         if (isApproved) {
//           if (intervalRef.current) {
//             clearInterval(intervalRef.current);
//           }
//           setCookieValue(
//             "EmailDoubleAuthExp",
//             new Date(Date.now() + 10 * 60000)
//           ); // Make sure setCookieValue is defined and imported
//           // If the response is successful, move to the next step
//           setOtp(Array(6).fill(""));
//           setModalStepNumber(3);
//           setIsProcessing(false);
//         }
//       }, 5000);
//     }
//     return () => {
//       if (intervalRef.current) {
//         clearInterval(intervalRef.current);
//       }
//     };
//   }, [ModalStepNumber]);
// 
//   const styles = {
//     phoneInputContainer: {
//       display: 'flex',      // Enables flexbox
//       justifyContent: 'center', // Centers children horizontally
//       alignItems: 'center', // If you also want to center vertically
//       // Add other styling as needed for the container
//     },
//     phoneInput: {
//       width: '240px'
//     }
//   };
// 
//   const handleSendOTPDoubleAuth = async (isForceSms) => {
//     setErrorMessage(''); // Clear the old error message before sending a new OTP
//     setIsProcessing(true);
//     try {
//       await sendOTPDoubleAuth('update_phone_mfa', isForceSms);
//       // If the response is successful, move to the next step
//       setModalStepNumber(2);
// 
//     } catch (error) {
//       // Assuming 't' is a translation function available in this scope
//       const errorMsg = error.message === "too_many_requests_try_later" ? t("too_many_requests_try_later") : t(error.message);
//       setErrorMessage(errorMsg); // Set new error message using the translation function
//     } finally {
//       setIsProcessing(false);
//     }
//   };
// 
//   const handleVerifyOTPDoubleAuth = async () => {
// 
//     setErrorMessage(''); // Clear the old error message before sending a new OTP
//     setIsProcessing(true);
//     const token = getCookieValue('firebaseToken'); // Make sure getCookieValue is defined and imported
// 
//     try {
//       const API_URL = await get_api_url();
//       const response = await fetch(`${API_URL}/api/auth/update-phone-mfa/verify-otp`, {
//         method: 'POST',
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`
//         },
//         body: JSON.stringify({
//           phone: value, // Ensure 'value' is defined in your component and holds the phone/phone number
//           otp_code: otp.join('')
//         })
//       });
// 
//       if (!response.ok) {
//         if (response.status === 500) {
//           throw new Error("unexpected_error"); // Using the key for translation function
//         }
//         if (response.status === 402) {
//           throw new Error("wrong_otp_code"); // Using the key for translation function
//         }
//         if (response.status === 400) {
//           throw new Error("otp_code_expired_please_request_a_new_one"); // Using the key for translation function
//         }
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }
// 
//       // save on the cookie key PhoneDoubleAuthExp that the double auth expires in 10 mins (save the expiratino time)
//       // calculate the time in 10 minutes from now
//       setCookieValue('PhoneDoubleAuthExp', new Date(Date.now() + 10 * 60000)); // Make sure setCookieValue is defined and imported
//       // If the response is successful, move to the next step
//       setOtp(Array(6).fill(""));
//       setModalStepNumber(3);
//     } catch (error) {
//       // Assuming 't' is a translation function available in this scope
//       const errorMsg = error.message === "too_many_requests_try_later" ? t("too_many_requests_try_later") : t(error.message);
//       setErrorMessage(errorMsg); // Set new error message using the translation function
//     } finally {
//       setIsProcessing(false);
//     }
//   };
// 
//   const handleVerifyPhoneOTP = async () => {
// 
//     setErrorMessage(''); // Clear the old error message before sending a new OTP
//     setIsProcessing(true);
//     const token = getCookieValue('firebaseToken'); // Make sure getCookieValue is defined and imported
// 
//     try {
//       const API_URL = await get_api_url();
//       const response = await fetch(`${API_URL}/api/auth/update-phone/verify-otp`, {
//         method: 'POST',
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`
//         },
//         body: JSON.stringify({
//           otp_type: 'verify_new_phone',
//           otp_code: otp.join('')
//         })
//       });
// 
//       if (!response.ok) {
//         if (response.status === 500) {
//           throw new Error("unexpected_error"); // Using the key for translation function
//         }
//         if (response.status === 402) {
//           throw new Error("wrong_otp_code"); // Using the key for translation function
//         }
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }
// 
//       // save on the cookie key PhoneDoubleAuthExp that the double auth expires in 10 mins (save the expiratino time)
//       // calculate the time in 10 minutes from now
//       setCookieValue('PhoneDoubleAuthExp', new Date(Date.now() + 5 * 60000)); // Make sure setCookieValue is defined and imported
//       // If the response is successful, move to the next step
//       setModalStepNumber(5);
//     } catch (error) {
//       // Assuming 't' is a translation function available in this scope
//       const errorMsg = error.message === "too_many_requests_try_later" ? t("too_many_requests_try_later") : t(error.message);
//       setErrorMessage(errorMsg); // Set new error message using the translation function
//     } finally {
//       setIsProcessing(false);
//     }
//   };
// 
//   const submit_update_phone_address = async () => {
//     setErrorMessage(''); // Clear the old error message before sending a new OTP
//     setIsProcessing(true);
//     const token = getCookieValue('firebaseToken'); // Make sure getCookieValue is defined and imported
// 
//     try {
//       const API_URL = await get_api_url();
//       const response = await fetch(`${API_URL}/api/user/profile/mobile/update`, {
//         method: 'POST',
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`
//         },
//         body: JSON.stringify({
//           new_phone: userNewPhoneRef.current, // Ensure 'value' is defined in your component and holds the phone/phone number
//         })
//       });
// 
//       if (!response.ok) {
//         if (response.status === 500) {
//           throw new Error("unexpected_error"); // Using the key for translation function
//         }
//         // if response is 400 and message is "Missing double OTP authentication" then the user has not yet verified the OTP
//         if (response.status === 400) {
//           const responseJson = await response.json();
//           if (responseJson.message === "Missing double OTP authentication") {
//             setCookieValue('PhoneDoubleAuthExp', new Date(Date.now())); // Expire the double auth cookie
//             throw new Error("missing_double_otp_authentication"); // Using the key for translation function
//           }
//           if (responseJson.message === "Phone already exists in Firebase") {
//             throw new Error("phone_not_accepted"); // Using the key for translation function
//           }
//           if (responseJson.fdt_error_code) {
//             throw new Error(responseJson.fdt_error_code);
//           }
//         }
//         if (response.status === 429) {
//           throw new Error("pleasewait30secs"); // Using the key for translation function
//         }
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }
// 
//       // If the response is successful, redirect to login
//       navigate('/login?action=clean-cookie&reason=please_login_with_new_phone_address');
//     } catch (error) {
//       const errorMsg = error.message === "too_many_requests_try_later" ? t("too_many_requests_try_later") : t(error.message);
//       setErrorMessage(errorMsg); // Set new error message using the translation function
//     } finally {
//       setIsProcessing(false);
//     }
//   };
// 
//   const handleSendVerificationPhoneOTP = async () => {
// 
//     const removeSpaces = (string) => string.replace(/\s+/g, '');
//     setErrorMessage(''); // Clear the old error message before sending a new OTP
//     setIsProcessing(true);
//     const token = getCookieValue('firebaseToken'); // Make sure getCookieValue is defined and imported
//     const userNewPhone_stored = removeSpaces(phoneInputRef.current.value);
//     userNewPhoneRef.current = userNewPhone_stored; // Store the phone in the ref
// 
//     try {
//       const API_URL = await get_api_url();
//       const response = await fetch(`${API_URL}/api/auth/update-phone/send-verification`, {
//         method: 'POST',
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`
//         },
//         body: JSON.stringify({
//           phone: userNewPhone_stored, // Ensure 'value' is defined in your component and holds the phone/phone number
//           otp_type: 'update_phone_mfa'
//         })
//       });
// 
// 
//       if (!response.ok) {
// 
//         const responseJson = await response.json();
// 
//         if (response.status === 500) {
//           if (responseJson.message === "Failed to send OTP: [HTTP 400] Unable to create record: The 'To' number XXX is not a valid phone number") {
//             throw new Error("phone_not_accepted"); // Using the key for translation function
//           }
//           throw new Error("unexpected_error"); // Using the key for translation function
//         }
//         // if response is 400 and message is "Missing double OTP authentication" then the user has not yet verified the OTP
//         if (response.status === 400) {
// 
//           if (responseJson.message === "Missing double OTP authentication") {
//             setCookieValue('PhoneDoubleAuthExp', new Date(Date.now())); // Expire the double auth cookie
//             throw new Error("missing_double_otp_authentication"); // Using the key for translation function
//           }
//         }
//         if (response.status === 429) {
//           throw new Error("pleasewait30secs"); // Using the key for translation function
//         }
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }
// 
//       // If the response is successful, move to the next step
//       setModalStepNumber(4);
// 
//     } catch (error) {
//       // Assuming 't' is a translation function available in this scope
//       const errorMsg = error.message === "too_many_requests_try_later" ? t("too_many_requests_try_later") : t(error.message);
//       setErrorMessage(errorMsg); // Set new error message using the translation function
//     } finally {
//       setIsProcessing(false);
//     }
//   };
// 
//   const ModalTitle = (stepNumber) => {
//     switch (stepNumber) {
//       case 2:
//         return `${t(
//           getCookieValue("double_auth_otp_delivery_method") === "push"
//             ? "waiting_to_approve_from_mobile"
//             : "verify_received_sms"
//         )}`;
//       case 3:
//         return `${t('enter_your_new_phone_address')}`; // Replace with actual text for step 3
//       case 4:
//         return `${t('confirm_code')}`; // Replace with actual text for step 3
//       case 5:
//         return `${t('comfirm_and_log_back_in')}`; // Replace with actual text for step 3  
//       default:
//         return `${t('verify_identity')}`;
//     }
//   }
// 
//   const step2_handleBackspaceOnSubmit = (e) => {
//     if (e.key === 'Backspace') {
//       // Move focus to the last input field of the OTP
//       const lastInputIndex = otp.length - 1;
//       inputsRef.current[lastInputIndex].focus();
//       e.preventDefault(); // Prevent the default backspace behavior
//     }
//   };
// 
//   const step4_handleBackspaceOnSubmit = (e) => {
//     if (e.key === 'Backspace') {
//       // Move focus to the last input field of the OTP
//       const lastInputIndex = otp.length - 1;
//       inputsRef.current[lastInputIndex].focus();
//       e.preventDefault(); // Prevent the default backspace behavior
//     }
//   };
// 
//   const ModalContent = ({ ModalStepNumber, t, otp, inputsRef }) => {
// 
//     const handleChange = (index, value) => {
// 
//       if (ModalStepNumber !== 2 && ModalStepNumber !== 4) return;
//       if (!/^[0-9]$/.test(value)) return;
// 
//       setOtp(prevOtp => {
//         const newOtp = [...prevOtp];
//         newOtp[index] = value;
//         return newOtp;
//       });
// 
//       // Focus the next input after a short delay to ensure the current input value is updated
//       setTimeout(() => {
//         if (index < otp.length - 1) {
//           // This is not the last OTP input, move focus to the next input if it exists
//           inputsRef.current[index + 1] && inputsRef.current[index + 1].focus();
//         } else if (index === otp.length - 1 && value) {
//           // This is the last OTP input and it has been filled, move focus to the submit button if it exists
//           if (ModalStepNumber === 2) {
//             step2_submitButtonRef.current && step2_submitButtonRef.current.focus();
//           }
//           if (ModalStepNumber === 4) {
//             step4_submitButtonRef.current && step4_submitButtonRef.current.focus();
//           }
//         }
//       }, 10);
//     };
// 
//     const handleKeyDown = (e, index) => {
//       if (ModalStepNumber !== 2 && ModalStepNumber !== 4) return;
// 
//       if (e.key === 'Backspace') {
//         e.preventDefault(); // Prevent the default backspace behavior in any case
// 
//         // If the current input is empty, focus and clear the previous one
//         if (otp[index] === "") {
//           const prevIndex = index - 1;
//           if (prevIndex >= 0) {
//             setOtp((prevOtp) => {
//               const newOtp = [...prevOtp];
//               newOtp[prevIndex] = ""; // Clear the previous input
//               return newOtp;
//             });
//             // Focus the previous input after state update
//             setTimeout(() => {
//               inputsRef.current[prevIndex].focus();
//             }, 0);
//           }
//         } else {
//           // If the current input is not empty, clear it
//           setOtp((prevOtp) => {
//             const newOtp = [...prevOtp];
//             newOtp[index] = "";
//             return newOtp;
//           });
//           // Maintain focus on the current input
//           setTimeout(() => {
//             inputsRef.current[index].focus();
//           }, 0);
//         }
//       }
//     };
// 
//     const [userNewPhone, setUserNewPhone] = useState('');
//     const setPhoneValue = (value) => {
//       setUserNewPhone(value);
//     };
// 
//     const onPhoneKeyPress = (e) => {
//       if (e.key === 'Enter' && !isProcessing) {
//         handleSendVerificationPhoneOTP();
//       }
//     };
// 
//     const renderContent = () => {
//       switch (ModalStepNumber) {
//         case 2:
//           return getCookieValue("double_auth_otp_delivery_method") ===
//             "push" ? (
//             <div>
//               <Typography
//                 variant="body1"
//                 gutterBottom
//                 style={{ textAlign: "center" }}
//               >
//                 {t("prefer_to_send_a_sms_instead")}
//               </Typography>
//               <Button
//                 variant="contained"
//                 
//                 disabled={isProcessing}
//                 onClick={() => handleSendOTPDoubleAuth(true)}
//               >
//                 {isProcessing ? (
//                   <CircularProgress size={24} color="inherit" />
//                 ) : (
//                   t("send_sms")
//                 )}
//               </Button>
//             </div>
//           ) : (
//             <>
//               <DialogContent>
//                 {errorMessage && (
//                   <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
//                 )}
//                 <div style={styles.step2_description}>
//                   {t("please_enter_6_digit_otp_received_via_sms")}
//                 </div>
//                 <Box
//                   sx={{
//                     width: '100%',
//                     display: 'flex',
//                     justifyContent: 'center',
//                     gap: '10px'
//                   }}
//                 >
//                   {otp.map((digit, index) => (
//                     <TextField
//                       key={index}
//                       variant="outlined"
//                       inputProps={{
//                         maxLength: 1,
//                         style: { width: "40px", textAlign: "center" },
//                       }}
//                       inputRef={(el) => (inputsRef.current[index] = el)}
//                       value={digit}
//                       onChange={(e) => handleChange(index, e.target.value)}
//                       onKeyDown={(e) => handleKeyDown(e, index)}
//                     />
//                   ))}
//                 </Box>
//               </DialogContent>
//             </>
//           );
//         case 3:
//           return (
//             <>
//               <div style={styles.step2_description}>
//                 {t('phone_update_step_three_description')}
//               </div>
//               <div style={styles.PhoneInputContainer}>
//                 <PhoneInput
//                   international
//                   defaultCountry="IT"
//                   value={userNewPhone}
//                   onChange={setPhoneValue}
//                   onKeyPress={onPhoneKeyPress}
//                   ref={phoneInputRef} // Not inputRef
//                 />
//               </div>
//             </>
//           );
//         case 4:
//           return (
//             <>
// 
//               <DialogContent>
//                 {errorMessage && (
//                   <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
//                 )}
//                 <div style={styles.step2_description}>
//                   {t("please_enter_6_digit_otp_received_via_sms")}
//                 </div>
//                 <Box
//                   sx={{
//                     width: '100%',
//                     display: 'flex',
//                     justifyContent: 'center',
//                     gap: '10px'
//                   }}
//                 >
//                   {otp.map((digit, index) => (
//                     <TextField
//                       key={index}
//                       variant="outlined"
//                       inputProps={{
//                         maxLength: 1,
//                         style: { width: "40px", textAlign: "center" },
//                       }}
//                       inputRef={(el) => (inputsRef.current[index] = el)}
//                       value={digit}
//                       onChange={(e) => handleChange(index, e.target.value)}
//                       onKeyDown={(e) => handleKeyDown(e, index)}
//                     />
//                   ))}
//                 </Box>
//               </DialogContent>
//             </>
//           );
//         case 5:
//           return (
//             <>
//               <div style={styles.step2_description}>
//                 {t('confirm_phone_update_you_have_to_login_again')}
//               </div>
//             </>
//           );
//         default:
//           // Content for step 1 and any other step not explicitly handled
//           return <div>{t('phone_update_step_one_description')}</div>;
//       }
//     };
// 
//     // Styles for the OTP input elements can be defined outside the component or inside like this
//     const styles = {
//       otpInputsContainer: {
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         flexDirection: 'row',
//         width: '100%'
//       },
//       PhoneInputContainer: {
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         flexDirection: 'row',
//         width: '100%'
//       },
//       otpInput: {
//         textAlign: 'center',
//         width: '2ch'
//       },
//       step2_description: {
//         paddingBottom: '20px'
//       },
//       step3_description: {
//         paddingBottom: '20px',
//         width: '100%'
//       }
//     };
// 
//     return (
//       <DialogContent>
//         {renderContent()}
//       </DialogContent>
//     );
//   };
// 
//   const ModalActions = ({ ModalStepNumber, t, otp, inputsRef, handleChange, handleKeyDown }) => {
//     const renderContent = () => {
//       switch (ModalStepNumber) {
//         case 2:
//           return (
//             <>
//               <Button
//                 onClick={() => setModalStepNumber(1)}
//                 variant="contained"
//                 
//                 disabled={isProcessing} // Disable the button when isProcessing is true
//               >
//                 {t('resend_sms')}
//               </Button>
//               <Button
//                 ref={step2_submitButtonRef}
//                 onKeyDown={step2_handleBackspaceOnSubmit}
//                 onClick={handleVerifyOTPDoubleAuth}
//                 variant="contained"
//                 
//                 disabled={isProcessing} // Disable the button when isProcessing is true
//               >
//                 {isProcessing ? (
//                   <CircularProgress size={24} color="inherit" />
//                 ) : (
//                   t('verify_otp')
//                 )}
//               </Button>
//             </>
//           );
//         case 3:
//           return (
//             <>
//               <Button
//                 onClick={handleSendVerificationPhoneOTP}
//                 variant="contained"
//                 
//                 disabled={isProcessing} // Disable the button when isProcessing is true
//               >
//                 {isProcessing ? (
//                   <CircularProgress size={24} color="inherit" />
//                 ) : (
//                   t('send_verification_request')
//                 )}
//               </Button>
//             </>
//           );
//         case 4:
//           return (
//             <>
//               <Button
//                 onClick={() => setModalStepNumber(3)}
//                 variant="contained"
//                 
//                 disabled={isProcessing} // Disable the button when isProcessing is true
//               >
//                 {t('resend_or_change_mail')}
//               </Button>
//               <Button
//                 ref={step4_submitButtonRef}
//                 onKeyDown={step4_handleBackspaceOnSubmit}
//                 onClick={handleVerifyPhoneOTP}
//                 variant="contained"
//                 
//                 disabled={isProcessing} // Disable the button when isProcessing is true
//               >
//                 {isProcessing ? (
//                   <CircularProgress size={24} color="inherit" />
//                 ) : (
//                   t('verify_otp')
//                 )}
//               </Button>
//             </>
//           );
//         case 5:
//           return (
//             <>
//               <Button
//                 onClick={submit_update_phone_address}
//                 variant="contained"
//                 
//                 disabled={isProcessing} // Disable the button when isProcessing is true
//               >
//                 {isProcessing ? (
//                   <CircularProgress size={24} color="inherit" />
//                 ) : (
//                   t('submit_update_phone_address')
//                 )}
//               </Button>
//             </>
//           );
//         default:
//           return (
//             <>
//               <Button
//                 onClick={() => handleSendOTPDoubleAuth(false)}
//                 variant="contained"
//                 
//                 disabled={isProcessing} // Disable the button when isProcessing is true
//               >
//                 {isProcessing ? (
//                   <CircularProgress size={24} color="inherit" />
//                 ) : (
//                   t('send_sms')
//                 )}
//               </Button>
//             </>
//           );
//       }
//     };
// 
//     return (
//       <DialogActions>
//         {renderContent()}
//       </DialogActions>
//     );
//   };
// 
//   useEffect(() => {
//     // Step setting logic based on PhoneDoubleAuthExp
//     if (PhoneDoubleAuthExp && !ModalStepNumber) {
//       const expirationDate = new Date(PhoneDoubleAuthExp);
//       const currentDate = new Date();
//       const difference = expirationDate - currentDate; // Difference in milliseconds
// 
//       // Convert difference from milliseconds to minutes and round it
//       const minutesLeft = Math.round(difference / 60000);
// 
//       // Only show alert and set modal step if there is still time left
//       if (minutesLeft > 0) {
//         // alert(`Time left: ${minutesLeft} minutes`);
//         setModalStepNumber(3);
//       }
//     }
// 
//     // Focusing logic
//     if (ModalStepNumber === 2 && inputsRef.current[0]) {
//       inputsRef.current[0].focus();
//     }
//     //  if (ModalStepNumber === 3) {
//     //    phoneInputRef.current.focus();
//     //  }
//     if (ModalStepNumber === 4 && inputsRef.current[0]) {
//       inputsRef.current[0].focus();
//     }
//   }, [ModalStepNumber, PhoneDoubleAuthExp, inputsRef]);
// 
//   return (
//     <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
//       <ModalTitle>
//         {ModalTitle(ModalStepNumber, t)} {/* Make sure to call the function */}
//       </ModalTitle>
//       {errorMessage && (
//         <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
//       )}
// 
//       <ModalContent
//         ModalStepNumber={ModalStepNumber}
//         t={t}
//         otp={otp}
//         inputsRef={inputsRef}
//       />
//       {(ModalStepNumber !== 2 ||
//         getCookieValue("double_auth_otp_delivery_method") !== "push") && (
//           <ModalActions
//             ModalStepNumber={ModalStepNumber}
//             t={t}
//             otp={otp}
//             inputsRef={inputsRef}
//           />
//         )}
//     </Dialog>
//   );
// 

export default ModalUpdatePhone;